export function mustGetEnv(key) {
    const value = process.env[key];
    if (!value) {
        console.warn(`Environment variable ${key} is not set.`);
    }
    return value;
}

export function getEnvOrDefault(key, defaultValue) {
    const value = process.env[key];
    if (!value) {
        return defaultValue;
    }
    return value;
}
