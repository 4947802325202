export default {
  methods: {
    async showConfirmationDialog({ title, subText }) {
      try {
        const result = await this.$swal({
          title: title,
          text: this.$t("You can't revert your action"),
          customClass: "custom-delete-popup",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: subText,
          cancelButtonText: this.$t("No"),
          showCloseButton: true,
          showLoaderOnConfirm: true,
        });

        return result.isConfirmed; // true if user confirmed, false if cancelled
      } catch (error) {
        console.error("Swal dialog error:", error);
        return false;
      }
    },
  },
};
