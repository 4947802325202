import Vue from "vue";

export default {
  namespaced: true,
  state: {
    customers: [],
    resellers: [],
    salesPartners: [],
    servicePartners: [],
    count: 0,
  },
  getters: {
    customers: (state) => state.customers,
    resellers: (state) => state.resellers,
    salesPartners: (state) => state.salesPartners,
    servicePartners: (state) => state.servicePartners,
    count: (state) => state.count,
  },
  mutations: {
    customers: (state, payload) => (state.customers = payload),
    resellers: (state, payload) => (state.resellers = payload),
    salesPartners: (state, payload) => (state.salesPartners = payload),
    servicePartners: (state, payload) => (state.servicePartners = payload),
    count: (state, payload) => (state.count = payload),
  },
  actions: {
    list({ commit }, queryParams) {
      return this.$apiService
        .get("/companies", {
          params: queryParams,
        })
        .then((res) => {
          commit("customers", res?.data?.data ?? []);
          commit("count", res?.data?.total ?? 0);
          return res;
        });
    },
    resellers({ commit }, queryParams) {
      return this.$apiService
        .get("/companies", {
          params: queryParams,
        })
        .then((res) => {
          commit("resellers", res?.data?.data ?? []);
          return res;
        });
    },
    getResellerPartner() {
      return this.$apiService.get("/get-reseller-partner").then((res) => {
        return res;
      });
    },
    getCredits({ commit }) {
      return this.$apiService.get(`/get-credits`).then((res) => {
        return res;
      });
    },
    salesPartners({ commit }, queryParams) {
      return this.$apiService
        .get("/companies", {
          params: queryParams,
        })
        .then((res) => {
          commit("salesPartners", res?.data?.data ?? []);
          return res;
        });
    },
    servicePartners({ commit }, queryParams) {
      return this.$apiService
        .get("/companies", {
          params: queryParams,
        })
        .then((res) => {
          commit("servicePartners", res?.data?.data ?? []);
          return res;
        });
    },
    create({}, payload) {
      return this.$apiService.post("/companies", payload);
    },
    registerCompany({}, payload) {
      return this.$apiService.post("/register-company", payload);
    },
    show({}, id) {
      return this.$apiService.get(`/companies/${id}`);
    },
    fetchCaptcha({}) {
      return this.$apiService.get(`/captcha/api`);
    },
    showReferralCompany({}, id) {
      return this.$apiService.get(`/show-referral-company/${id}`);
    },
    update({}, { id, data }) {
      return this.$apiService.put(`/companies/${id}`, data);
    },
    destroy({}, id) {
      return this.$apiService.delete(`/companies/${id}`);
    },
    importCsv({}, payload) {
      return this.$apiService.post("/companies-import", payload);
    },
    uploadLogo({}, payload) {
      return this.$apiService.post("/upload-company-logo", payload);
    },
    deleteLogo({}) {
      return this.$apiService.post("/delete-company-logo");
    },
    listReferrals({}, queryParams) {
      return this.$apiService.get("/referral", {
        params: queryParams,
      });
    },
  },
};
