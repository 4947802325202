import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import AuthService from "../services/auth.service";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Home",
        requiresAuth: true,
      },
    },
    {
      path: "/second-page",
      name: "second-page",
      component: () => import("@/views/SecondPage.vue"),
      meta: {
        pageTitle: "Second Page",
      },
    },
    {
      path: "/packages-old",
      name: "packages-old",
      component: () => import("@/views/packages-old/Packages.vue"),
      meta: {
        pageTitle: "Extension Packages",
      },
    },
    {
      path: "/imprint",
      name: "imprint",
      component: () => import("@/views/Imprint.vue"),
      meta: {
        pageTitle: "Imprint",
      },
    },
    {
      path: "/dataprivacy",
      name: "dataprivacy",
      component: () => import("@/views/DataPrivacy.vue"),
      meta: {
        pageTitle: "Data Privacy",
      },
    },
    {
      path: "/account-setting",
      name: "account-setting",
      component: () => import("@/views/account-setting/AccountSetting.vue"),
      meta: {
        pageTitle: "Account Settings",
        requiresAuth: true,
      },
    },
    {
      path: "/faq",
      name: "faq",
      component: () => import("@/views/faq/Faq.vue"),
      meta: {
        pageTitle: "FAQ",
        requiresAuth: true,
      },
    },
    {
      path: "/apps/invoice/preview/:id",
      name: "apps-invoice-preview",
      component: () => import("@/views/InvoicePreview.vue"),
    },
    {
      path: "/2d-to-3d",
      name: "2d-to-3d",
      component: () => import("@/views/2DTo3D.vue"),
      meta: {
        pageTitle: "2D to 3D Reconstruction",
        requiresAuth: true,
      },
    },
    {
      path: "/cloud-storage",
      name: "cloud-storage",
      component: () => import("@/views/CloudStorage.vue"),
      meta: {
        pageTitle: "Cloud Storage",
        requiresAuth: true,
      },
    },
    {
      path: "/",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        requiresAuth: false,
      },
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/views/Register.vue"),
      meta: {
        layout: "full",
        requiresAuth: false,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        requiresAuth: true,
      },
    },
    {
      path: "/api-keys",
      name: "api-keys",
      component: () => import("@/views/api-keys/Index.vue"),
      meta: {
        pageTitle: "API Keys",
        permission: "api-keys",
        requiresAuth: true,
      },
    },
    {
      path: "/api-keys/create",
      name: "api-keys-create",
      component: () => import("@/views/api-keys/Create.vue"),
      meta: {
        pageTitle: "Create API Key",
        permission: "api-keys",
        requiresAuth: true,
      },
    },
    {
      path: "/api-keys/:id/edit",
      name: "api-keys/edit",
      component: () => import("@/views/api-keys/Edit.vue"),
      meta: {
        pageTitle: "Edit API Key",
        permission: "api-keys",
        requiresAuth: true,
      },
    },
    {
      path: "/customers",
      name: "customers",
      component: () => import("@/views/customers/Index.vue"),
      meta: {
        pageTitle: "Customers",
        permission: "backoffice-company",
        requiresAuth: true,
      },
    },
    {
      path: "/customers/create",
      name: "customers-create",
      component: () => import("@/views/customers/Create.vue"),
      meta: {
        pageTitle: "Create Customer",
        permission: "backoffice-company",
        requiresAuth: true,
      },
    },
    {
      path: "/unit",
      name: "unit",
      component: () => import("@/views/unit/Index.vue"),
      meta: {
        pageTitle: "Unit",
        permission: "backoffice-unit",
        requiresAuth: true,
      },
    },
    {
      path: "/customers/:id/edit",
      name: "customers/edit",
      component: () => import("@/views/customers/Edit.vue"),
      meta: {
        pageTitle: "Edit Customer",
        permission: "backoffice-company",
        requiresAuth: true,
      },
    },
    {
      path: "/global-configuration",
      name: "global-configuration",
      component: () => import("@/views/global-configuration/Index.vue"),
      meta: {
        pageTitle: "Global Configuration",
        permission: "backoffice-global-configuration",
        requiresAuth: true,
      },
    },
    {
      path: "/invoices",
      name: "invoices",
      component: () => import("@/views/invoices/Index.vue"),
      meta: {
        pageTitle: "Invoices",
        permission: "backoffice-invoice",
        requiresAuth: true,
      },
    },
    {
      path: "/invoices/create",
      name: "invoices-create",
      component: () => import("@/views/invoices/Create.vue"),
      meta: {
        pageTitle: "Create Invoice",
        permission: "backoffice-invoice",
        requiresAuth: true,
      },
    },
    {
      path: "/invoices/:id/edit",
      name: "invoices/edit",
      component: () => import("@/views/invoices/Edit.vue"),
      meta: {
        pageTitle: "Edit Invoice",
        permission: "backoffice-invoice",
        requiresAuth: true,
      },
    },
    {
      path: "/invoices/:id/show",
      name: "invoices/show",
      component: () => import("@/views/invoices/Show.vue"),
      meta: {
        pageTitle: "Show Invoice",
        permission: "backoffice-invoice",
        requiresAuth: true,
      },
    },
    {
      path: "/coupon-codes",
      name: "coupon-codes",
      component: () => import("@/views/coupon-codes/Index.vue"),
      meta: {
        pageTitle: "Coupon Codes",
        permission: "backoffice-coupon-code",
        requiresAuth: true,
      },
    },
    {
      path: "/coupon-codes/create",
      name: "coupon-codes-create",
      component: () => import("@/views/coupon-codes/Create.vue"),
      meta: {
        pageTitle: "Create Coupon Code",
        permission: "backoffice-coupon-code",
        requiresAuth: true,
      },
    },
    {
      path: "/coupon-codes/:id/edit",
      name: "coupon-codes/edit",
      component: () => import("@/views/coupon-codes/Edit.vue"),
      meta: {
        pageTitle: "Edit Coupon Code",
        permission: "backoffice-coupon-code",
        requiresAuth: true,
      },
    },
    {
      path: "/coupon-codes/:id/show",
      name: "coupon-codes/show",
      component: () => import("@/views/coupon-codes/Show.vue"),
      meta: {
        pageTitle: "Show Coupon Code",
        permission: "backoffice-coupon-code",
        requiresAuth: true,
      },
    },
    {
      path: "/packages",
      name: "packages",
      component: () => import("@/views/packages/Index.vue"),
      meta: {
        pageTitle: "DentalTwin Coins Packages",
        permission: "backoffice-package",
        requiresAuth: true,
      },
    },
    {
      path: "/packages/create",
      name: "packages-create",
      component: () => import("@/views/packages/Create.vue"),
      meta: {
        pageTitle: "Create DentalTwin Coins Package",
        permission: "backoffice-package",
        requiresAuth: true,
      },
    },
    {
      path: "/packages/:id/edit",
      name: "packages/edit",
      component: () => import("@/views/packages/Edit.vue"),
      meta: {
        pageTitle: "Edit DentalTwin Coins Package",
        permission: "backoffice-package",
        requiresAuth: true,
      },
    },
    {
      path: "/packages/:id/show",
      name: "packages/show",
      component: () => import("@/views/packages/Show.vue"),
      meta: {
        pageTitle: "Show DentalTwin Coins Package",
        permission: "backoffice-package",
        requiresAuth: true,
      },
    },
    {
      path: "/invoice-reminder-levels",
      name: "invoice-reminder-levels",
      component: () => import("@/views/invoice-reminder-levels/Index.vue"),
      meta: {
        pageTitle: "Invoice Reminder Levels",
        permission: "backoffice-invoice-reminder-level",
        requiresAuth: true,
      },
    },
    {
      path: "/invoice-reminder-levels/create",
      name: "invoice-reminder-levels-create",
      component: () => import("@/views/invoice-reminder-levels/Create.vue"),
      meta: {
        pageTitle: "Create Invoice Reminder Level",
        permission: "backoffice-invoice-reminder-level",
        requiresAuth: true,
      },
    },
    {
      path: "/invoice-reminder-levels/:id/edit",
      name: "invoice-reminder-levels/edit",
      component: () => import("@/views/invoice-reminder-levels/Edit.vue"),
      meta: {
        pageTitle: "Edit Invoice Reminder Level",
        permission: "backoffice-invoice-reminder-level",
        requiresAuth: true,
      },
    },
    {
      path: "/news",
      name: "news",
      component: () => import("@/views/news/Index.vue"),
      meta: {
        pageTitle: "News",
        permission: "backoffice-news",
        requiresAuth: true,
      },
    },
    {
      path: "/news/create",
      name: "news-create",
      component: () => import("@/views/news/Create.vue"),
      meta: {
        pageTitle: "Create News",
        permission: "backoffice-news",
        requiresAuth: true,
      },
    },
    {
      path: "/news/:id/edit",
      name: "news-edit",
      component: () => import("@/views/news/Edit.vue"),
      meta: {
        pageTitle: "Edit News",
        permission: "backoffice-news",
        requiresAuth: true,
      },
    },
    {
      path: "/news/:id/show",
      name: "news-show",
      component: () => import("@/views/news/Show.vue"),
      meta: {
        pageTitle: "Show News",
        permission: "backoffice-news",
        requiresAuth: true,
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/users/Index.vue"),
      meta: {
        pageTitle: "Users",
        permission: "user",
        requiresAuth: true,
      },
    },
    {
      path: "/users/create",
      name: "users-create",
      component: () => import("@/views/users/Create.vue"),
      meta: {
        pageTitle: "Create User",
        permission: "user",
        requiresAuth: true,
      },
    },
    {
      path: "/users/:id/edit",
      name: "users/edit",
      component: () => import("@/views/users/Edit.vue"),
      meta: {
        pageTitle: "Edit User",
        permission: "user",
        requiresAuth: true,
      },
    },
    {
      path: "/permissions",
      name: "permissions",
      component: () => import("@/views/permissions/Index.vue"),
      meta: {
        pageTitle: "Permissions",
        permission: "permission",
        requiresAuth: true,
      },
    },
    {
      path: "/permissions/create",
      name: "permissions-create",
      component: () => import("@/views/permissions/Create.vue"),
      meta: {
        pageTitle: "Create Permission",
        permission: "permission",
        requiresAuth: true,
      },
    },
    {
      path: "/permissions/:id/edit",
      name: "permissions/edit",
      component: () => import("@/views/permissions/Edit.vue"),
      meta: {
        pageTitle: "Edit Permission",
        permission: "permission",
        requiresAuth: true,
      },
    },
    {
      path: "/document-assignment",
      name: "document-assignment",
      component: () => import("@/views/document-assignment/Index.vue"),
      meta: {
        pageTitle: "Document Assignment",
        permission: "backoffice-document-assignment",
        requiresAuth: true,
      },
    },
    {
      path: "/mail-template-assignment",
      name: "mail-template-assignment",
      component: () => import("@/views/mail-template-assignment/Index.vue"),
      meta: {
        pageTitle: "Mail Template Assignment",
        permission: "backoffice-mail-template-assignment",
        requiresAuth: true,
      },
    },
    {
      path: "/roles",
      name: "roles",
      component: () => import("@/views/roles/Index.vue"),
      meta: {
        pageTitle: "Roles",
        permission: "role",
        requiresAuth: true,
      },
    },
    {
      path: "/roles/create",
      name: "roles-create",
      component: () => import("@/views/roles/Create.vue"),
      meta: {
        pageTitle: "Create Role",
        permission: "role",
        requiresAuth: true,
      },
    },
    {
      path: "/roles/:id/edit",
      name: "roles/edit",
      component: () => import("@/views/roles/Edit.vue"),
      meta: {
        pageTitle: "Edit Role",
        permission: "role",
        requiresAuth: true,
      },
    },
    {
      path: "/settings",
      name: "settings",
      component: () => import("@/views/settings/Setting.vue"),
      meta: {
        pageTitle: "Settings",
        requiresAuth: true,
        permission: "backoffice-settings",
      },
    },
    {
      path: "/my-cases",
      name: "my-cases",
      component: () => import("@/views/my-cases/Index.vue"),
      meta: {
        pageTitle: "My Cases",
        requiresAuth: true,
        permission: "backoffice-case-instance",
      },
    },
    {
      path: "/my-cases/:id/edit",
      name: "my-cases-edit",
      component: () => import("@/views/my-cases/Edit.vue"),
      meta: {
        pageTitle: "My Cases Edit",
        requiresAuth: true,
        permission: "backoffice-case-instance",
      },
    },
    {
      path: "/tickets",
      name: "tickets",
      component: () => import("@/views/tickets/Index.vue"),
      meta: {
        pageTitle: "Tickets",
        requiresAuth: true,
        permission: "backoffice-ticket",
      },
    },
    {
      path: "/tickets/create",
      name: "tickets-create",
      component: () => import("@/views/tickets/Create.vue"),
      meta: {
        pageTitle: "Create Ticket",
        requiresAuth: true,
        permission: "backoffice-ticket",
      },
    },
    {
      path: "/tickets/:id/edit",
      name: "tickets-edit",
      component: () => import("@/views/tickets/Edit.vue"),
      meta: {
        pageTitle: "Edit Ticket",
        requiresAuth: true,
        permission: "backoffice-ticket",
      },
    },
    {
      path: "/payment-history",
      name: "payment-history",
      component: () => import("@/views/payment-history/Index.vue"),
      meta: {
        pageTitle: "Payment History",
        requiresAuth: true,
        permission: "backoffice-payment-history",
      },
    },
    {
      path: "/referral",
      name: "referral",
      component: () => import("@/views/referral/Index.vue"),
      meta: {
        pageTitle: "Referrals",
        requiresAuth: true,
        permission: "backoffice-referral",
      },
    },
    {
      path: "/mail-templates",
      name: "mail-templates",
      component: () => import("@/views/mail-templates/Index.vue"),
      meta: {
        pageTitle: "Mail Templates",
        requiresAuth: true,
        permission: "backoffice-mail-template",
      },
    },
    {
      path: "/mail-templates/create",
      name: "mail-templates-create",
      component: () => import("@/views/mail-templates/Create.vue"),
      meta: {
        pageTitle: "Create Mail Template",
        requiresAuth: true,
        permission: "backoffice-mail-template",
      },
    },
    {
      path: "/mail-templates/:id/edit",
      name: "mail-templates-edit",
      component: () => import("@/views/mail-templates/Edit.vue"),
      meta: {
        pageTitle: "Edit Mail Template",
        requiresAuth: true,
        permission: "backoffice-mail-template",
      },
    },
    {
      path: "/document-templates",
      name: "document-templates",
      component: () => import("@/views/document-templates/Index.vue"),
      meta: {
        pageTitle: "Document Templates",
        requiresAuth: true,
        permission: "backoffice-document-template",
      },
    },
    {
      path: "/document-templates/create",
      name: "document-templates-create",
      component: () => import("@/views/document-templates/Create.vue"),
      meta: {
        pageTitle: "Create Document Template",
        requiresAuth: true,
        permission: "backoffice-document-template",
      },
    },
    {
      path: "/document-templates/:id/edit",
      name: "document-templates-edit",
      component: () => import("@/views/document-templates/Edit.vue"),
      meta: {
        pageTitle: "Edit Document Template",
        requiresAuth: true,
        permission: "backoffice-document-template",
      },
    },
    {
      path: "/document-templates/:id/show",
      name: "document-templates-show",
      component: () => import("@/views/document-templates/Show.vue"),
      meta: {
        pageTitle: "Show Document Template",
        requiresAuth: true,
        permission: "backoffice-document-template",
      },
    },
    {
      path: "*",
      redirect: "error-404",
      requiresAuth: true,
    },
  ],
});

// router.beforeResolve((to, from, next) => {
//   // If this isn't an initial page load.
//   if (to.name) {
//     // Start the route progress bar.
//     NProgress.start();
//   }

//   next()

// })

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters["auth/authenticated"];

  // If route doesn't require authentication, proceed
  if (!to.meta.requiresAuth) return next();

  // Redirect to login if not authenticated
  if (!isAuthenticated) return next({ name: "login" });

  // Fetch permissions and scopes if not already loaded
  if (!store.getters["permissions/permissionsGlobal"]?.length) {
    try {
      await store.dispatch("permissions/permissionsGlobal", {
        limit_start: 0,
        limit_count: 1500,
      });

      const userPermissions = [];
      const permissions = store.getters["permissions/permissionsGlobal"];
      const scopes = JSON.parse(localStorage.getItem("scopes")) ?? {};

      for (let scope in scopes) {
        const scopePermissions = scopes[scope]?.permissions ?? [];
        scopePermissions.forEach((permission) => {
          const foundPermission = permissions?.find(
            (perm) => perm.value === permission && perm.scope === scope
          );
          if (foundPermission) userPermissions.push(foundPermission.title);
        });
      }

      store.commit("auth/userPermissions", userPermissions);

      if (to.meta.permission) {
        const userPermissions = store.getters["auth/userPermissions"] || [];

        // Check if the user has any permission that matches or starts with the base permission
        const hasPermission = to.meta.permission
          ? userPermissions.some((permission) =>
              permission.startsWith(to.meta.permission)
            )
          : true;

        const hasRoleAdmin = Vue.prototype.$hasRole("admin");

        if (!hasRoleAdmin) {
          if (!hasPermission) {
            return next({ path: "/error-404" });
          }
        }
      }
    } catch (error) {
      console.error("Error loading permissions or scopes:", error);
    }
  }

  next(); // Proceed to the route
});

const DEFAULT_TITLE = "DentalTwin - Your Dentist as a Service";
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });

  // Complete the animation of the route progress bar.
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
  NProgress.done();
});

export default router;
